import * as React from 'react';
import { graphql } from 'gatsby';
import '../../../pages/styles.scss';
import {BasePageData, BasePageFooter, BasePageHeader} from '../../../helpers/base-page';
import {MarkdownRemark} from '../../../models/markdown-remark';
import {TitleSection} from './title-section';
import {ArticleCard} from '../article-card';
import readingTime from 'reading-time';
import AvatarPlaceholder from '../../../images/man.svg';
import styled from 'styled-components';
import {device} from '../../../models/devices';

interface CategoryPageData extends BasePageData {
  category: MarkdownRemark<{
    name: string;
    urlFriendlyName: string;
  }>;
  articles: MarkdownRemark<{
    title: string;
    urlFriendlyName: string;
    subTitle: string;
    category: string;
    author: string;
    image: string;
    Body: string;
  }>
  authors: MarkdownRemark<{
    firstName: string;
    lastName: string;
    image: string;
  }>
}

const ArticlesList = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 39px;
  column-gap: 31px;
  max-width: 1170px;
  margin: -80px auto 50px;

  @media screen and ${device.tablet} {
    grid-template-columns: 1fr;
    margin: -40px 24px 40px;
  }
`;

const CategoryPage = ({data}: {data: CategoryPageData}): JSX.Element => {
  const articles = data.articles.edges
    .map(({node}) => node.frontmatter)
    .map(article => ({
      ...article,
      author: data.authors.edges
        .map(i => i.node.frontmatter)
        .find(author => article.author === `${author.firstName}-${author.lastName}`),
      readingTime: readingTime(article.Body).text
    }));

  const category = data.category.edges[0].node.frontmatter;

  return (
    <React.Fragment>
      <BasePageHeader
        siteTitle={data.metaInfo.edges[0].node.frontmatter.siteTitle}
        siteDescription={data.metaInfo.edges[0].node.frontmatter.siteDescription}
        siteDescriptionTitle={data.metaInfo.edges[0].node.frontmatter.siteDescriptionTitle}
        siteImage={data.metaInfo.edges[0].node.frontmatter.siteImage}
        siteUrl={data.metaInfo.edges[0].node.frontmatter.siteUrl}
        logoLight={data.logos.edges[0].node.frontmatter.logoHeaderLight}
        logoDark={data.logos.edges[0].node.frontmatter.logoHeaderDark}
        logoShort={data.logos.edges[0].node.frontmatter.logoShort}
        links={data.navigation.edges[0].node.frontmatter.topCenterNavigation}
        modules={data.modules.edges.map(m => m.node.frontmatter)}
        usecases={data.usecases.edges.map(u => u.node.frontmatter)}
        hubspotFormId={data.hubspot.edges[0].node.frontmatter.hubSpotFormId}
        hubspotPortalId={data.hubspot.edges[0].node.frontmatter.hubSpotPortalId}
        contactFormConfig={data.contactFormConfig.edges[0].node.frontmatter}
        contentType={'blog-post'}
      />
      <TitleSection header={category.name}/>
      <ArticlesList>
        {articles.map((article, index) => (
          <ArticleCard
            key={index}
            img={article.image}
            categoryName={article.category}
            categoryUrl={category.urlFriendlyName}
            title={article.title}
            urlFriendlyName={article.urlFriendlyName}
            authorAvatar={article.author?.image ?? AvatarPlaceholder}
            authorFirstName={article.author?.firstName ?? ''}
            authorLastName={article.author?.lastName ?? ''}
            readingTime={article.readingTime}
          />
        ))}
      </ArticlesList>
      <BasePageFooter
        feedbackTitle={data.contact.edges[0].node.frontmatter.title}
        feedbackLabel={data.contact.edges[0].node.frontmatter.subText}
        feedbackButtonText={data.contact.edges[0].node.frontmatter.buttonText}
        feedbackInputPlaceholder={data.contact.edges[0].node.frontmatter.inputPlaceholder}
        navigation={data.navigation}
        integrations={data.integrations}
        blogArticles={data.blogArticles}
        blogCategories={data.blogCategories}
        features={data.features.edges.map(f => f.node.frontmatter)}
        footerLogo={data.logos.edges[0].node.frontmatter.logoFooter}
      />
    </React.Fragment>
  );
};

export default CategoryPage;

export const pageQuery = graphql`
  query($url: String!, $categoryName: String!) {
    ...BaseData
    category: allMarkdownRemark(
      filter: {
        fileAbsolutePath: {regex: "/^(?!.*/site/).*categories/"},
        frontmatter: {urlFriendlyName: {eq: $url}}}
    ) {
      edges {
        node {
          frontmatter {
            name
            urlFriendlyName
          }
        }
      }
    }
    articles: allMarkdownRemark(
      filter: {
        fileAbsolutePath: {regex: "/^(?!.*/site/).*blogs/"},
        frontmatter: {category: {eq: $categoryName}}
      }
    ) {
      edges {
        node {
          frontmatter {
            title
            urlFriendlyName
            category
            author
            image
            Body
          }
        }
      }
    }
    authors: allMarkdownRemark(
      filter: {
        fileAbsolutePath: {regex: "/^(?!.*/site/).*authors/"},
      }
    ) {
      edges {
        node {
          frontmatter {
            firstName
            lastName
            image
          }
        }
      }
    }
  }
`;
