import React from 'react';
import Background from '../../../images/impact-background.png';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import {device} from '../../../models/devices';

const Container = styled.section`
  background: url(${Background}) no-repeat top center;
  background-size: 100% 100%;
  padding-bottom: 152px;
  padding-top: 184px;

  @media screen and ${device.tablet} {
    background-size: auto 748px;
    padding: 115px 24px 80px;
  }
`;

const Wrapper = styled.div`
  max-width: 1170px;
  margin: 0 auto;
`;

const Header = styled.h1`
  font-size: 48px;
  color: white;
  font-weight: 500;
  width: 100%;
  max-width: 600px;
  line-height: 1.37em;
  letter-spacing: -0.3px;
  text-align: center;
  margin: 0 auto 42px;

  @media screen and ${device.tablet} {
    font-size: 32px;
    line-height: 1.5;
    max-width: initial;
    margin-bottom: 19px;
    text-align: center;
  }
`;

export const TitleSection = (props: {
  header: string;
}): JSX.Element => {
  return (
    <Container>
      <Wrapper>
        <Header><ReactMarkdown>{props.header}</ReactMarkdown></Header>
      </Wrapper>
    </Container>
  );
};
